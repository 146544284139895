/*.cr-app {
    display: flex;
    width: 24% !important;
    height: 100%;
    padding: 0;
    margin: 0;
    left: 546px !important;
    overflow-x: hidden;
    top: 44px!important;
}*/
.sub_label {
	font-size: 14px;
	margin-left: 30px;
}

@media (min-width: 576px){
    .modal-dialog {
        max-width: 651px !important;
        margin: 1.75rem auto;
    }
}

.form-control.datepicker{
 width:600px !important;
}

