div#as-react-datatable-container {
    width: 1206px !important;
}
.rounded-circle {
    border-radius: 50% !important;
    background-color: currentcolor !important;
}

.errormsg{
	color:red !important;
}
.roiplan_table {
	width: 100%;
}
.roiplan_table input {
	width: 200px;
}

.react_table_count .kmwNMJ div:first-child {
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
}

.react_table_count .rdt_TableCell:first-child {
        max-width: 300px !important;
    min-width: 376px !important;
}
.react_table_count .rdt_TableCol:first-child {
        max-width: 300px !important;
    min-width: 376px !important;
}
div#as-react-datatable-container {
    width: 1169px !important;
}

